import React, { useState, useEffect } from 'react';
import './App.css';
import NNsimulator from './NeuralNetwork.js';
import logo from './logo100.png';
import ReactGA from "react-ga4";
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
// import rawarticle1 from './static/1.1.html'

ReactGA.initialize("G-BDNNPFDH0S");

// import LossGraph from './LossGraph';

// const home_endpoint = 'http://127.0.0.1:5001/home'
const home_endpoint = 'https://backend-5v3d3gapha-ue.a.run.app/home'

const App = () => {

  // State to track the current page
  const [currentPage, setCurrentPage] = useState('home');

  const [article11, setArticle11] = useState('');

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/"});
  }, []);

  const About = () => {
    return (
      <div className="App" >
        <div className = "navigation-container">
          <ColoredLine color="#00DEFF"/>
          <div className = "watermark">
          <img src={logo} alt="Logo" />
          </div>
          <div className = "navigation-buttons">
          <button className="pagebutton" onclick = {(event) => goToPage('home')}> {<Link to="/" style={{ color: 'white' }} >Home</Link>} </button>
          <button className="pagebutton" onclick = {(event) => goToPage('simulators')}> {<Link to="/simulators" style={{ color: 'white' }} >Simulators</Link>} </button>
          <button className="clickedpagebutton" onclick = {(event) => goToPage('about')}> {<Link to="/about" style={{ color: 'white' }} >About</Link>} </button>
          </div>
          </div>
        <br />
        <br />
        <br />
        <br />
        <br />
        <h1>About</h1>
        <h2>Our Mission</h2>
        <p>Neuryte was founded by two ambitious high schoolers with the goal of expanding technological literacy and education. As of right now, about one-third of Americans still lack basic computational skills, and as the world continues to develop new technologies, such as Artificial Intelligence, it is ever so important that people have the opportunity and resources to learn. Neuryte hopes to insight passion within both students and adults alike, and offer an easy to use platform to explore and learn more about the technological world.</p>
        <br />
        <h2>Team</h2>
        <p>Chief Executive Officer: Andrew Pang</p>
        <p>Chief Operations Officer: Amy Feng</p>
        <br />
        <h2>Contact Us</h2>
        <p>Email: contactneuryte@gmail.com</p>
        <p>Instagram: @neuryteofficial</p>
      </div>
    );
  };

  const Simulators = () => {
    return (
      <div className="App" >
        <div className = "navigation-container">
          <ColoredLine color="#00DEFF"/>
          <div className = "watermark">
          <img src={logo} alt="Logo" />
          </div>
          <div className = "navigation-buttons">
          <button className="pagebutton" onclick = {(event) => goToPage('home')}> {<Link to="/" style={{ color: 'white' }} >Home</Link>} </button>
          <button className="clickedpagebutton" onclick = {(event) => goToPage('simulators')}> {<Link to="/simulators" style={{ color: 'white' }} >Simulators</Link>} </button>
          <button className="pagebutton" onclick = {(event) => goToPage('about')}> {<Link to="/about" style={{ color: 'white' }} >About</Link>} </button>
          </div>
          </div>
        <br />
        <br />
        <br />
        <br />
        <br />
        <h1>Simulators</h1>
        <br />
        <br />
        <div style={{ textAlign: 'left', marginLeft: '30%' }}>
        <Link to="/simulators/neuralnetwork" style={{ color: 'white' }} ><h2>Neural Network Simulator:</h2></Link>
        <p style = {{marginLeft: '0px'}}>The training process of a Neural Network visualized for easy understanding.</p>
        </div>
      </div>
    );
  };

  const Explore = () => {

    const [htmlContent, setHtmlContent] = useState('');

    // const htmlContent =require('./static/1-1.html');

    useEffect(() => {
      // Fetch the HTML file based on the article ID
      const fetchArticle = async () => {
        const response = await fetch(`./static/doc.html`);
        const data = await response.text();
        setHtmlContent(data);
      };
  
      fetchArticle();
    }, []);
      
    // useEffect(() => {
    //   fetch('./static/1-1.html')
    //     .then(response => {
    //       if (!response.ok) {
    //         throw new Error('Failed to fetch HTML content');
    //       }
    //       return response.text()
    //     })
    //     .then(data => {
    //       setHtmlContent(data)
    //     })
    //     .catch(error => {
    //       console.error('Error fetching HTML content:', error)
    //     })
    // }, []);

    return (
      <div className="App">
        <div dangerouslySetInnerHTML = {{ __html: htmlContent }}/>
      </div>
    );
  };

      // return (
    //   <div className="App" >
    //     <div className = "navigation-container">
    //       <ColoredLine color="#00DEFF"/>
    //       <div className = "watermark">
    //       <img src={logo} alt="Logo" />
    //       </div>
    //       <div className = "navigation-buttons">
    //       <button className="pagebutton" onclick = {(event) => goToPage('home')}> {<Link to="/" style={{ color: 'white' }} >Home</Link>} </button>
    //       <button className="clickedpagebutton" onclick = {(event) => goToPage('simulators')}> {<Link to="/simulators" style={{ color: 'white' }} >Simulators</Link>} </button>
    //       <button className="pagebutton" onclick = {(event) => goToPage('about')}> {<Link to="/about" style={{ color: 'white' }} >About</Link>} </button>
    //       </div>
    //       </div>
    //     <br />
    //     <br />
    //     <br />
    //     <br />
    //     <br />
    //     <iframe src={rawarticle1} ></iframe>
    //     {/* <pre style={{ whiteSpace: 'pre-wrap', textAlign: 'left', margin: '10%' }}>{article11}</pre> */}
    //   </div>
    // );

  const Home = () => {
    return(
      <div className="App">
        <div className = "navigation-container">
          <ColoredLine color="#00DEFF"/>
          <div className = "watermark">
          <img src={logo} alt="Logo" />
          </div>
          <div className = "navigation-buttons">
          <button className="clickedpagebutton" onclick = {(event) => goToPage('home')}> {<Link to="/" style={{ color: 'white' }} >Home</Link>} </button>
          <button className="pagebutton" onclick = {(event) => goToPage('simulators')}> {<Link to="/simulators" style={{ color: 'white' }} >Simulators</Link>} </button>
          <button className="pagebutton" onclick = {(event) => goToPage('about')}> {<Link to="/about" style={{ color: 'white' }} >About</Link>} </button>
          </div>
          </div>
      <br />
      <br />
      <br />
      <br />
      <br />
      <h1>Neuryte</h1>
      <br />
      <NNsimulator></NNsimulator>
    </div>
    );
  };

  const NeuralNetwork = () => {
    return(
      <div className="App">
        <div className = "navigation-container">
          <ColoredLine color="#00DEFF"/>
          <div className = "watermark">
          <img src={logo} alt="Logo" />
          </div>
          <div className = "navigation-buttons">
          <button className="pagebutton" onclick = {(event) => goToPage('home')}> {<Link to="/" style={{ color: 'white' }} >Home</Link>} </button>
          <button className="clickedpagebutton" onclick = {(event) => goToPage('simulators')}> {<Link to="/simulators" style={{ color: 'white' }} >Simulators</Link>} </button>
          <button className="pagebutton" onclick = {(event) => goToPage('about')}> {<Link to="/about" style={{ color: 'white' }} >About</Link>} </button>
          </div>
          </div>
      <br />
      <br />
      <br />
      <br />
      <br />
      <NNsimulator></NNsimulator>
    </div>
    );
  }

  const goToPage = (page) => {
    setCurrentPage(page);
  };

  const goToHomePage = () => {
    setCurrentPage('home');
  };

  const ColoredLine = ({ color }) => (
    <hr
        style={{
            marginTop: 0,
            color: color,
            backgroundColor: color,
            height: 5,
            borderColor: color,
            width: 2000
        }}
    />
);

return (
  <Router>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/simulators" element={<Simulators />} />
        <Route path="/simulators/neuralnetwork" element={<NeuralNetwork />} />
      <Route path="/about" element={<About />} />
    </Routes>
  </Router>
);

}



export default App;
